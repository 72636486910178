import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppContext from '../Store/AppContext'
import RouteSuspense from '@ifca-root/react-component/src/containers/App/Router/RouteSuspense'
import { PrivateRoute } from './PrivateRoute'
import { Login } from '@hr-root/hr-employee-react/src/containers/AccountModule/Login'
import { ForgotPassword } from 'containers/AccountModule/ForgotPassword'
import { ActivatedUser } from 'containers/AccountModule/ActivatedUser'
import { Logout } from 'containers/AccountModule/Logout'

const Routes = () => {
  const { globalState } = useContext(AppContext) as any
  return (
    <Switch>
      <Route path="/activate/:token">
        <ActivatedUser />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>

      {/* <Route path="/reset-password/:token">
        <ResetPassword />
      </Route> */}

      {globalState.routes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el.props}>
            <RouteSuspense>{el.component}</RouteSuspense>
          </PrivateRoute>
        )
      })}
    </Switch>
  )
}

export default Routes
